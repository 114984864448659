<template>
  <div class="orderImport">
    <div class="tips">
      <p class="tips_title">
        导入Excel批量下单
      </p>
      <p class="tips_text">
        1. 下载模版 <el-button size="small" type="primary" class="whtie submit" @click="downFile">下载模版</el-button><br/>
        2. 根据模版填写内容，每次上传条数不超过100条，超过取前100条<br/>
        3. 点击“上传Excel”按钮，选择文件并上传<br/>
        4. 点击“提交”按钮，批量创建下单<br/>
        5. 批量创建订单成功，返回“我的订单”查看订单下单情况<br>
    </p>
    </div>
    <div class="downfile">
      <div class="downfile_title">{{ uploadResult.length==0 ? '第一步：上传Excel' : '第二步：上传成功，创建订单' }}</div>
      <div class="downfile_cont" v-if="uploadResult.length==0">
        <el-upload
          :action= " baseURL + '/upload/orderImport'"
          :headers="{ token: $store.getters.userInfo.token }"
          :on-success="successUpload"
          :on-exceed="exceedUpload"
          :before-upload="beforeUpload"
          :on-progress="progressUpload"
          :show-file-list="true"
          :limit="1"
          :timeout="600000"
          :on-remove="removeUpload"
        >
          <el-button size="small" type="primary" class="white">上传Excel</el-button>
        </el-upload>
      </div>

      <div class="downfile_cont" v-if="uploadResult.length > 0">
        <el-button size="small" type="primary" class="whtie submit" @click="createOrder" v-if="result.length == 0">创建订单</el-button>
        <el-button size="small" type="primary" class="whtie submit" @click="resetUpload" v-if="result.success">再次上传</el-button>
        <div class="uploadResult" v-if="result.success">
          导入结果：
          成功：<span style="color: green;">{{result.success.length}}</span>条，
          失败：<span style="color: #ff0000;">{{result.fail.length + result.not.length}}</span>条

          <div class="uploadResult" v-if="result.fail.length > 0">
            <div>创建订单失败：</div>
            <template class="row pro-item" v-for="item in result.fail">
              {{ item }}，
            </template>
          </div>
          <div class="uploadResult" v-if="result.not.length > 0">
            <div>商品不存在或已下架：</div>
            <template class="row pro-item" v-for="item in result.not">
              {{ item }}，
            </template>
          </div>
        </div>
      </div>
      <div class="uploadResult" v-if="uploadTips">{{ uploadTips }}</div>
      
    </div>

  </div>
</template>
<script>
import { download } from "@/utils/plugin";
import { mapState } from "vuex";
export default {
  name: "",
  props: {},
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      uploadResult: "", //上传结果
      uploadTips: "",
      result: [], //创建订单结果
    };
  },
  computed: {
    ...mapState(["config"]),
    ...mapState({
      commonData: (state) => state.user.commonData,
    }),
  },
  watch: {},
  methods: {
    // 下载模版
    downFile() {
      download(
        "/downfile/index",
        {
          f: "orderImport"
        },
        `批量下单${new Date().getTime()}.xlsx`
      );
    },
    // 创建订单
    async createOrder() {
      if(this.uploadTips != "") return;
      this.uploadTips = "正在创建订单，请内心等待，不要刷新页面..."
      let { code, data } = await this.$api.createByExcelUrl({
        url: this.uploadResult
      });
      if (code == 1) {
        this.result = data
        this.uploadTips = ""
      }
    },

    // 再次上传
    resetUpload() {
      this.uploadResult = ""
      this.result = []
      this.tips = ""
    },
    // 上传之前
    beforeUpload(file) {
      // 类型校验
      var name = file.name;
      var fileName = name.substring(name.lastIndexOf(".") + 1).toLowerCase();
      if (fileName != "xlsx" && fileName != "xls") {
          this.$message.warning(`请选择.xlsx格式文件上传！`);
          file.value = "";
          return false
      }
      // 大小校验
      var size = file.size / 1024;
      // if (size < 20) { //空白的Excel表最大为20KB，当小于它及判断为Excel内容为空
      //     this.$message.warning(`导入的Excel表不能为空！`);
      //     file.value = "";
      //     return false
      // } 
      if (size > 10000) {
          this.$message.warning(`导入的Excel表不能大于10M！`);
          file.value = "";
          return false
      }          

      return true
    },
    // 上传进度
    progressUpload(res) {
      if(res.percent < 100) {
        this.$message.warning("上传进度 "+res.percent+"%");
        return false
      }
    },
    // 上传成功
    successUpload(res) {
      let { code, data } = res;
      if(code == 1) { 
        this.$message.success('上传成功');
        this.uploadResult = data.url;
        console.log(this.uploadResult)
      }
    },
    // 移除文件
    removeUpload(file, fileList) {
      this.uploadResult = ""
    },
    // 上传限制
    exceedUpload(files, fileList) {
      this.$message.warning(`当前限制上传 1 个文件`);
    },
  },
  created() {
    this.orderId = this.$route.query.id;
    this.getOrderDetail();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.tips_title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.tips_text {
  font-size: 14px;
  line-height: 24px;
  padding-top: 20px;
}
.downfile {
  margin: 50px 20% 20px;
  text-align: center;
}
.downfile_title {
  font-weight: 600;
  font-size: 16px;
}
.downfile_cont {
  margin: 30px 0;
  text-align: center;
}
.uploadResult {
  font-size: 14px;
  padding: 10px 0;
}
</style>